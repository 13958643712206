import { Image, Text } from "@clipboard-health/ui-react";

import { DeprecatedGlobalAppV1Paths } from "../App/paths";
import { APP_V2_USER_EVENTS, logEvent } from "../lib/analytics";
import { LinkCard } from "../lib/Links/LinkCard";
import { useDefinedWorker } from "../Worker/useDefinedWorker";
import mobilePhoneImage from "./assets/images/mobilePhone.png";
import { useCareerAgentConfig } from "./useCareerAgentConfig";

export function CareerAgentBanner() {
  const { userId: workerId } = useDefinedWorker();
  const { title, description } = useCareerAgentConfig();

  return (
    <LinkCard
      to={{ pathname: DeprecatedGlobalAppV1Paths.CAREER_AGENT }}
      title={
        <Text bold variant="body2">
          {title}
        </Text>
      }
      subtitle={<Text variant="caption">{description}</Text>}
      variant="info"
      leadingIcon={<Image src={mobilePhoneImage} alt="Phone" width={42} height={42} />}
      onClick={() => {
        logEvent(APP_V2_USER_EVENTS.CAREER_AGENT_PAGE_VIEWED, { workerId });
      }}
    />
  );
}
