import { CbhFeatureFlag, useCbhFlag } from "../FeatureFlags";

export function useCareerAgentConfig() {
  return useCbhFlag(CbhFeatureFlag.CAREER_AGENT_CONFIG, {
    defaultValue: {
      showBanner: false,
      title: "Clipboard Career Agent",
      description:
        "Call or text for jobhunting support, tips and tricks from the community, and education support.",
      screenName: "Clipboard Career Agent",
      url: "https://0bbogwlgm7e.typeform.com/to/Na2osXAd#user_id=",
    },
  });
}
