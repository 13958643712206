import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

import { type ApiResponse, post } from "../../api/api";

const validateOtpRequestSchema = z.object({
  phone: z.string(),
  otp: z.string(),
  isSignup: z.boolean(),
});

export enum ValidateOtpError {
  OTP_EXPIRED = "OtpExpired",
  INVALID_OTP = "InvalidOtp",
  INVALID_USER_FOR_LOGIN = "InvalidUserForLogin",
  INVALID_USER_FOR_SIGNUP = "InvalidUserForSignup",
}

const validateOtpResponseSchema = z.discriminatedUnion("message", [
  z.object({
    message: z.literal("otpIsValid"),
    data: z.object({
      firebaseToken: z.string(),
      agentDeletionRequestCancelled: z.boolean(),
    }),
  }),
  z.object({
    message: z.literal("Invalid OTP"),
    error: z.literal(ValidateOtpError.INVALID_OTP),
  }),
  z.object({
    message: z.literal("Otp Expired"),
    error: z.literal(ValidateOtpError.OTP_EXPIRED),
  }),
  z.object({
    message: z.literal(
      "We could not find your account. Please try again or contact support for further assistance."
    ),
    error: z.enum([
      ValidateOtpError.INVALID_USER_FOR_LOGIN,
      ValidateOtpError.INVALID_USER_FOR_SIGNUP,
    ]),
  }),
]);

export type ValidateOtpRequest = z.infer<typeof validateOtpRequestSchema>;
export type ValidateOtpResponse = z.infer<typeof validateOtpResponseSchema>;

export const VALIDATE_OTP_PATH = "/agentProfile/v2/validateOTP";

export function useValidateOtp(
  options: UseMutationOptions<ApiResponse<ValidateOtpResponse>, AxiosError, ValidateOtpRequest> = {}
): UseMutationResult<ApiResponse<ValidateOtpResponse>, AxiosError, ValidateOtpRequest> {
  return useMutation({
    mutationFn: async (data: ValidateOtpRequest) =>
      await post({
        url: VALIDATE_OTP_PATH,
        data,
        requestSchema: validateOtpRequestSchema,
        responseSchema: validateOtpResponseSchema,
      }),
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.VALIDATE_OTP_FAILURE,
    },
    ...options,
  });
}
