import { type EveryMessage } from "sendbird-uikit";

export enum CustomMessageTypes {
  SHIFT_REVIEW = "SHIFT_REVIEW",
  FCM_REQUEST = "FCM_REQUEST",
  FCM_APPROVAL = "FCM_APPROVAL",
  FCM_REJECT = "FCM_REJECT",
  INPUT_ETA = "INPUT_ETA",
  INPUT_ETA_SUBMITTED = "INPUT_ETA_SUBMITTED",
  SHIFT_INVITE_REQUEST = "SHIFT_INVITE_REQUEST",
  SHIFT_INVITE_RESPONSE = "SHIFT_INVITE_RESPONSE",
}

export interface ShiftInviteMessageProps {
  message: EveryMessage;
}
