import { isDefined } from "@clipboard-health/util-ts";
import { Button, CardActions, Chip } from "@mui/material";
import { type ShiftInviteMessageProps } from "@src/appV2/Chat/type";
import { parseShiftInviteChatMessage } from "@src/appV2/Chat/utils";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { useHistory } from "react-router-dom";

import { RootPaths } from "../App/paths";
import { usePendingShiftInvite } from "../Shifts/ShiftInvites/api/usePendingShiftInvite";
import { useShiftInviteActions } from "../Shifts/ShiftInvites/api/useShiftInviteActions";
import { SHIFT_INVITES_ROUTE_PATH } from "../Shifts/ShiftInvites/constant";
import { ShiftInviteItemProvider } from "../Shifts/ShiftInvites/context/shiftInviteItemContext";
import { ShiftInviteCardAction } from "../Shifts/ShiftInvites/ShiftInviteCardAction";
import { ShiftInviteItemDialog } from "../Shifts/ShiftInvites/ShiftInviteItemDialog";
import { ShiftInviteStatus } from "../Shifts/ShiftInvites/types";
import { CbhCustomChatMessage } from "./CbhCustomChatMessage";

const shiftInviteStatusToColor: Record<string, "default" | "error" | "success"> = {
  EXPIRED: "default",
  ACCEPTED: "success",
  DECLINED: "error",
};

export function ShiftInviteRequestMessage(props: ShiftInviteMessageProps) {
  const { agentMessage, shiftInviteId, status } = parseShiftInviteChatMessage(props);
  const {
    acceptShiftInvite,
    declineShiftInvite,
    isLoadingAcceptOrDecline,
    acceptOrDeclineRequest,
  } = useShiftInviteActions();
  const { data: shiftInvite } = usePendingShiftInvite(shiftInviteId);
  const history = useHistory();

  if (!isDefined(agentMessage) || status === ShiftInviteStatus.DELETED) {
    // If we return null ,the message will render as standard sendbird message.
    // This is a custom message but we don't have enough data to render it properly
    return <span />;
  }

  return (
    <ShiftInviteItemProvider>
      <CbhCustomChatMessage message={agentMessage}>
        <CardActions>
          {isDefined(shiftInvite) && status === ShiftInviteStatus.PENDING && (
            <ShiftInviteCardAction
              isLoading={isLoadingAcceptOrDecline}
              shiftInviteAction={acceptOrDeclineRequest?.status}
              onAcceptShiftInvite={async () => {
                await acceptShiftInvite({ shiftInvite });
                logEvent(APP_V2_APP_EVENTS.SHIFT_INVITE_IN_CHAT, {
                  action: "ACCEPTED",
                  shiftInviteId,
                });
              }}
              onDeclineShiftInvite={async () => {
                await declineShiftInvite({ shiftInviteId: shiftInvite.id });
                logEvent(APP_V2_APP_EVENTS.SHIFT_INVITE_IN_CHAT, {
                  action: "DECLINED",
                  shiftInviteId,
                });
              }}
            />
          )}
          {isDefined(status) && status !== ShiftInviteStatus.PENDING && (
            <Chip
              label={status.toUpperCase()}
              sx={{ width: "100%" }}
              color={shiftInviteStatusToColor[status.toUpperCase()]}
              variant="outlined"
            />
          )}

          {!isDefined(shiftInvite) && !isDefined(status) && (
            <Button
              variant="text"
              size="small"
              onClick={() => {
                history.push(`${RootPaths.APP_V2_HOME}/${SHIFT_INVITES_ROUTE_PATH}`);
              }}
            >
              Go to Shift Invites
            </Button>
          )}
        </CardActions>
      </CbhCustomChatMessage>
      {isDefined(shiftInvite) && <ShiftInviteItemDialog shiftInvite={shiftInvite} />}
    </ShiftInviteItemProvider>
  );
}
