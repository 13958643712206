import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

import { type ApiResponse, post } from "../../api/api";
import { AuthOtpErrorCode } from "../LoginSignUp/types";

const otpChannelSchema = z.enum(["sms", "call", "whatsapp"]);

const otpRequestSchema = z.object({
  isSignup: z.boolean(),
  phone: z.string(),
  channel: otpChannelSchema.optional(),
});

const requestOtpSuccessResponseSchema = z.object({
  data: z.string().optional(),
  message: z.string(),
  /**
   * error is a string that is interspersed in the server code
   * See https://github.com/ClipboardHealth/clipboard-health/blob/development/projects/backend-main/src/routes/agentProfile/controller/requestOTP.ts#L42
   * TODO - should we use `z.enum` here? If so, we will need to increase
   * confidence about syncing all error codes with the API code.
   */
  error: z.string().optional(),
});

const requestOtpErrorResponseSchema = z.object({
  message: z.string(), // error message to display to the user
  error: z.nativeEnum(AuthOtpErrorCode),
});

export type OtpRequestChannel = z.infer<typeof otpChannelSchema>;
export type OtpRequest = z.infer<typeof otpRequestSchema>;
export type RequestOtpSuccessResponse = z.infer<typeof requestOtpSuccessResponseSchema>;
export type RequestOtpErrorResponse = z.infer<typeof requestOtpErrorResponseSchema>;

export const REQUEST_OTP_PATH = "/agentProfile/requestOTP";

export function useRequestOtp(
  options: UseMutationOptions<
    ApiResponse<RequestOtpSuccessResponse>,
    AxiosError<RequestOtpErrorResponse>,
    OtpRequest
  > = {}
): UseMutationResult<
  ApiResponse<RequestOtpSuccessResponse>,
  AxiosError<RequestOtpErrorResponse>,
  OtpRequest
> {
  return useMutation({
    mutationFn: async (data: OtpRequest) => {
      return await post({
        url: REQUEST_OTP_PATH,
        data,
        requestSchema: otpRequestSchema,
        responseSchema: requestOtpSuccessResponseSchema,
      });
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.REQUEST_OTP_FAILURE,
    },
    ...options,
  });
}
