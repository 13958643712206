import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Link } from "@mui/material";
import { AuthMode } from "@src/appV2/Auth/LoginSignUp/libs";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { SupportArticles, SupportContext } from "@src/appV2/support/constants";

interface Props {
  authMode: AuthMode;
  supportLink: string | undefined;
}

export function OtpDeliveryHelpMessage(props: Props) {
  const { authMode, supportLink } = props;

  if (authMode !== AuthMode.LOGIN && !isDefined(supportLink)) {
    return null;
  }

  const supportContext =
    authMode === AuthMode.LOGIN
      ? SupportContext.LOGIN_OTP_DELIVERY
      : SupportContext.SIGN_UP_OTP_DELIVERY;

  return (
    <Text color="error">
      If you still have not received the code, please{" "}
      {authMode === AuthMode.LOGIN ? "check your email or " : ""}
      <Link
        href={supportLink}
        target="_blank"
        onClick={() => {
          logEvent(APP_V2_USER_EVENTS.OPENED_SUPPORT_ARTICLE, {
            articleLink: supportLink,
            articleName: SupportArticles.HOW_TO_CONTACT_SUPPORT,
            supportContext,
          });
        }}
      >
        contact support
      </Link>
      .
    </Text>
  );
}
