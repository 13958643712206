import { Title } from "@clipboard-health/ui-react";
import { Box } from "@mui/material";
import { CbhLogo, FULL_SCREEN_LAYOUT_TEXT_COLOR } from "@src/appV2/lib";
import { type ReactElement } from "react";

const tagLines = ["Great rates.", "Instant payouts.", "Lots of shifts."];

export function AuthHeadline(): ReactElement {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: {
          xs: 2,
          sm: 2,
          md: 3,
        },
      }}
    >
      {tagLines.map((tagLine) => (
        <Title
          key={tagLine}
          component="h1"
          sx={{
            color: FULL_SCREEN_LAYOUT_TEXT_COLOR,
          }}
        >
          {tagLine}
        </Title>
      ))}
      <CbhLogo />
    </Box>
  );
}
