import { Text } from "@clipboard-health/ui-react";
import { Box, Link } from "@mui/material";
import { type ReactElement } from "react";

export function AuthFooter(): ReactElement {
  return (
    <Box
      sx={{
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Text>
        <Link
          href="https://clipboardhealth.com/terms-of-service"
          target="_blank"
          sx={{
            color: "inherit",
            textDecorationColor: "inherit",
            "&:hover": {
              color: "inherit",
            },
          }}
        >
          Terms of Service
        </Link>
      </Text>
      <Text>
        <Link
          href="https://clipboardhealth.com/privacy-policy"
          target="_blank"
          sx={{
            color: "inherit",
            textDecorationColor: "inherit",
            "&:hover": {
              color: "inherit",
            },
          }}
        >
          Privacy Policy
        </Link>
      </Text>
      <Text variant="body2">Messaging and data rates may apply</Text>
    </Box>
  );
}
