import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

import { type ApiResponse, post } from "../../api/api";

const sendLoginLinkRequestSchema = z.object({
  email: z.string(),
  url: z.string(),
});

const sendLoginLinkResponseSchema = z.union([
  z.object({
    message: z.string(),
    statusCode: z.number(),
  }),
  z.object({
    success: z.boolean(),
  }),
]);

const sendLoginLinkErrorResponseSchema = z.object({
  message: z.string(),
  error: z.string(),
});

export type SendLoginLinkRequest = z.infer<typeof sendLoginLinkRequestSchema>;
export type SendLoginLinkResponse = z.infer<typeof sendLoginLinkResponseSchema>;
export type SendLoginLinkErrorResponse = z.infer<typeof sendLoginLinkErrorResponseSchema>;

export const REQUEST_LOGIN_LINK_PATH = "/agentProfile/sendLoginLink";

export function useRequestLoginLink(
  options: UseMutationOptions<
    ApiResponse<SendLoginLinkResponse>,
    AxiosError<SendLoginLinkErrorResponse>,
    SendLoginLinkRequest
  > = {}
): UseMutationResult<ApiResponse<SendLoginLinkResponse>, AxiosError, SendLoginLinkRequest> {
  return useMutation({
    mutationFn: async (data: SendLoginLinkRequest) =>
      await post({
        url: REQUEST_LOGIN_LINK_PATH,
        data,
        requestSchema: sendLoginLinkRequestSchema,
        responseSchema: sendLoginLinkResponseSchema,
      }),
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.REQUEST_LOGIN_LINK_FAILURE,
    },
    ...options,
  });
}
