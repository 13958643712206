import { isDefined } from "@clipboard-health/util-ts";

import { CbhCustomChatMessage } from "./CbhCustomChatMessage";
import { type ShiftInviteMessageProps } from "./type";
import { parseShiftInviteChatMessage } from "./utils";

export function ShiftInviteResponseMessage(props: ShiftInviteMessageProps) {
  const { agentMessage } = parseShiftInviteChatMessage(props);
  return isDefined(agentMessage) ? <CbhCustomChatMessage message={agentMessage} /> : null;
}
